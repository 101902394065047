.sidebar-off{
    opacity: 0;
    position:absolute;
    pointer-events: none;
    transition: 100ms ease-in;
    transition-property: opacity;
  }
.show-sidebar{
    transition: 200ms ease-in-out;
    transition-property: opacity;
    opacity: 1;
    display: table-column;
    background-color: var(--sidebar-color);
    width: 100%;
    height: 100vh;
    z-index: 2;
    position: fixed;
    left: unset;
    top: unset;
}
ul.sidebar{
    padding-left: 0;
}
.mobname{
    display: none;
}
svg {
    cursor: pointer;
    filter: var(--svg-invert);
}
a {
    text-decoration: unset;
    color: unset;
}
.sidebar > li > a {
    padding: 1rem;
    display: block;
}
.sidebar > li {
    margin: 1rem 0;
}
svg .show-sidebar{
    width: calc(10px + 3vw);
    height: calc(10px + 3vw);
}
@media screen and (max-width: 768px) {
  
    svg{
        width: calc(10px + 3vw);
      }
    nav.mobile{
        position: fixed;
        left: 1.5vw;
    }
    nav.main-nav{
        display: none;
    }
    .menu-sandwich{
        display: flex;
        margin-left: 2em;
        width: calc(20px + 2vw);
        height: calc(20px + 1.75vh);
        margin: 1rem;
        align-self: center;
    }
    .sidebar li > a {
      font-size: calc(10px + 1.65vw);
    }

    .preview-pic > img {
      width: 100vw;
    }
    .preview-pic > * {
      width: 100vw;
    }
    .preview-pic > picture > img {
      width: 100vw;
    }
    .brand-mobile{
      display: inherit;
      text-shadow: var(--brand-text-shadow);
      font-weight: bold;
      font-size: calc(12px + 0.7vh);
      text-transform: uppercase;
      font-family: var(--main-text);
      margin-left: 0.5rem;
      margin-bottom: 0.5rem;
      margin-top: 1.25rem;
    }
    p.br-title {
      font-size: calc(6px + 0.8vh);
      margin-left: 0.5rem;
      text-transform: uppercase;
    }

    .maingrid{
      display: grid;
      grid-template-columns: auto;
      margin-left: unset;
    }
    .grid-element:hover .preview-pic{
      transition: opacity 350ms ease-in-out;
      transition-property: opacity;
      -webkit-transition: opacity 350ms ease-in-out;
      -moz-transition: opacity 350ms ease-in-out;
      -o-transition:opacity 350ms ease-in-out;
      opacity: 0.75;
    }
    main{ 
      padding: 0;
    }
    .long-title,  .normal-title{
      letter-spacing: 0.1rem;
    }
    .grid-container{
      width: 100vw;
      grid-template-columns: 1fr 1fr;
    }
    p.br-mobile{
      display:unset;
    }
    .j-between{
      display: flex;
    }
}