@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
       url('../fonts/montserrat-v25-latin_cyrillic-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/montserrat-v25-latin_cyrillic-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700 - latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(''),
       url('../fonts/montserrat-v25-latin_cyrillic-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/montserrat-v25-latin_cyrillic-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
.art-info {
  padding: 0 2rem ;
  font-size: 12px;
  line-height: 1.6;
  color: #4a4a4a;
  text-align: start;
}
.video-description {
  padding: calc(min(5%, 2rem ));
  font-size: 12px;
  line-height: 1.6;
  color: #4a4a4a;
  text-align: start;
  max-width: 1400px;
}
.descr{
  text-align: start;
  font-size: 14px;
}
article {
  max-width: 580px;
  padding-bottom: 2rem;
}

.contact-container {
  display: block;
}

.contact-container > .space > img {
  width: calc(max(390px, 30vw));
  /* max-height: 400px; */
  /* max-width: calc(min( 300px, 30%)); */
  min-width: calc(max( 155px, 22%));
  object-fit: cover;
  padding: 1rem 0.5rem ;
  margin: 0 1.5rem;
  max-height: 80vh;
}

.con-2{
    grid-template-columns: 1fr 1fr !important;
  }
  .contact {
    font-size: calc(1.2rem + 0.8vw);
    text-transform: unset;
    font-weight: 600;
  }
  .contact-div{
    background-color: white;
    margin: clamp(1rem, 3rem, 8vw);
    padding: 2rem clamp(1rem, 4rem, 8vw);
    min-height: 50vh;
    max-width: 1200px;
    min-width: 280px;
    border-radius: 2rem;
  }
  .c-info{
    margin-top: 3.5rem;
    letter-spacing: 0.3px;
    padding: 5%;
  }
  .c-info > p{
    text-align: left;
    font-size: calc(0.5rem + 0.8vw);
  }
  
  .city{
    margin-bottom: 2.5rem;
  }

  .ss-remainder-1 > *:last-child {
    grid-column: span 2;
  }
  .ss-remainder-2 > *:last-child {
    grid-column: span 2;
  }
  @media screen and (min-width: 768px) {

    .remainder-1 > *:last-child {
      grid-column: span 2;
    }
    .remainder-2 > *:last-child {
      grid-column: span 2;
    }
    .maingrid.remainder-1> a:last-child  > div > div > picture > img{
      width: 100%;
      aspect-ratio: 28/9;
    }
    .contact-container > .space > img {
      margin: 0 ; 
    }
    .contact-container {
      padding: 1rem;
      justify-content: center;
      display: flex;
    }
  }
    .icons > * > svg{
      width: calc(10px + 0.7vw); 
  }
    
  .root {
    max-width: 100vw;
    display: grid;
    grid-template-rows: auto 1fr auto;
  }
  .menu > li {
    display: inline-block;
    margin: var(--menu-margin);
    cursor: pointer;
    font-family: var(--main-text);
  }
  
  .un:hover::after {
    transition: 200ms ease-in-out;
    width: 100%;
  }
  .un.active:hover::after {
    transition: none;
  }
  
  .un.active::after {
    width: 0;
  }
  
  ul.menu {
    display: flex;
    justify-content: end;
    align-items: center;
  }
  
  .brand {
    /* padding: var(--menu-margin); */
    margin-left: 10px;
    margin-bottom: 0.5rem;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    font-family: var(--main-text);
    font-size: calc(10px + 1.2vh);
  }
  .overlay-titles {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    width: max-content;
  }
  .j-end {
    display: flex;
    justify-content: end;
  }
  li.active > a {
    text-decoration: underline;
    text-underline-position: under;
  }
  
  .film-title{
    margin: 1rem;
  }
  p.type {
    margin: 0;
  }
  
  .grid-element:nth-child(2)>.portfolio-text>h3 {
    max-width: 60%;
  }
  .video {
    display: none;
    object-fit: cover;
    position: absolute;
  }
  
  .grid-element:hover .preview-pic{
    transition: opacity 350ms ease-in-out;
    -webkit-transition: opacity 350ms ease-in-out;
    -moz-transition: opacity 350ms ease-in-out;
    -o-transition:opacity 350ms ease-in-out;
    transition-property: opacity;
    opacity: 0.80;
  }
  .grid-element:hover .portfolio-text > h3{
    transition: text-shadow 500ms ease-in-out;
    text-shadow: 0px 4px 3px rgba(0,0,0,0.2),
    0px 8px 13px rgba(0,0,0,0.1),
    0px 18px 23px rgba(0,0,0,0.1);
  }
  .video-holder{
    display: flex;
    justify-content: center;
    background-color: var(--video-bckg);
  }
  
  .grid-container{
    display: grid;
    /* column-gap: 1em;
    row-gap: 0.1%; 
    width: 98vw;
    padding: 0 1rem; */
    grid-template-columns: repeat(3, 1fr);
  }
  .preview-ss{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .mt-3{
    margin-top: 3vh;
  }
  .aspect{
    aspect-ratio: 18 / 9;
  }
  .project-info {
    margin: 2rem;
  }
  .project-title {
    margin: 2rem;
  }
  .project-title > h2{
    font-weight: 600;
  }
  .project-description{
   line-height: 2;
   width: 70%;
   margin: 2rem;
  }
  .project-description > p{
    text-align: left;
  }
  iframe{
    margin-top: 1.25rem;
    padding: 0 2vw;
    max-height: 70vh;
  }
  .sidebar-off{
    opacity: 0;
    position:absolute;
    pointer-events: none;
    transition: 100ms ease-in;
    transition-property: opacity;
  }
.show-sidebar{
    transition: 200ms ease-in-out;
    transition-property: opacity;
    opacity: 1;
    display: table-column;
    background-color: var(--sidebar-color);
    width: 100%;
    height: 100vh;
    z-index: 2;
    position: fixed;
    left: unset;
    top: unset;
}
ul.sidebar{
    padding-left: 0;
}
.mobname{
    display: none;
}
svg {
    cursor: pointer;
    filter: var(--svg-invert);
}
a {
    text-decoration: unset;
    color: unset;
}
.sidebar > li > a {
    padding: 1rem;
    display: block;
}
.sidebar > li {
    margin: 1rem 0;
}
svg .show-sidebar{
    width: calc(10px + 3vw);
    height: calc(10px + 3vw);
}
.icons > a:first-child {
  margin: 0;
}
.icons > a {
    margin: 5%;
}
.icons > a > svg{
    fill: #575757; 
}
.icons {
  padding: 8px;
}

@media screen and (max-width: 768px) {
    .art-info, .icons {
      padding: 0 2rem ;
    }
    svg{
        width: calc(10px + 3vw);
      }
    nav.mobile{
        position: fixed;
        left: 1.5vw;
    }
    nav.main-nav{
        display: none;
    }
    .menu-sandwich{
        display: flex;
        margin-left: 2em;
        width: calc(20px + 2vw);
        height: calc(20px + 1.75vh);
        margin: 1rem;
        align-self: center;
      }
      .sidebar li > a {
        font-size: calc(10px + 1.65vw);
      }

      .preview-pic > img {
        width: 100vw;
      }
      .preview-pic > * {
        width: 100vw;
      }
      .preview-pic > picture > img {
        width: 100vw;
      }
      .brand-mobile{
        display: inherit;
        text-shadow: var(--brand-text-shadow);
        font-weight: bold;
        font-size: calc(12px + 0.7vh);
        text-transform: uppercase;
        font-family: var(--main-text);
        margin-left: 0.5rem;
        margin-bottom: 0.5rem;
        margin-top: 1.25rem;
      }
      p.br-title {
        font-size: calc(6px + 0.8vh);
        margin-left: 0.5rem;
        text-transform: uppercase;
      }

      .maingrid{
        display: grid;
        grid-template-columns: auto;
        margin-left: unset;
      }
      .grid-element:hover .preview-pic{
        transition: opacity 350ms ease-in-out;
        transition-property: opacity;
        -webkit-transition: opacity 350ms ease-in-out;
        -moz-transition: opacity 350ms ease-in-out;
        -o-transition:opacity 350ms ease-in-out;
        opacity: 0.75;
      }
      main{ 
        padding: 0;
      }
      .long-title,  .normal-title{
        letter-spacing: 0.1rem;
      }
      .grid-container{
        width: 100vw;
        grid-template-columns: 1fr 1fr;
      }
      p.br-mobile{
        display:unset;
      }
      .j-between{
        display: flex;
      }
}